<template>
  <div>
    <navbar />

    <div class="main-container">
      <article-privacy />
    </div>
  </div>
</template>

<script>
import navbar from './components/navbars.vue'
import ArticlePrivacy from './components/article_privacy.vue'

export default {
  name: 'Term',
  components: { navbar, ArticlePrivacy },
}
</script>

<style lang="scss" scoped>

</style>
