<template>
  <div>
    <article class="container">
      <h2 class="article-heading">
        เงื่อนไขการให้บริการ | Terms of Service
        <time
          class="article-heading__published-date"
        >ปรับปรุงล่าสุด: 10 มีนาคม 2567</time>
      </h2>
      <div class="article-content">
        <p>ประกาศเกี่ยวกับความเป็นส่วนตัวของไซเบอร์เช็ค(Cyber-Check)</p>
        <p>
          Cyber Police (ซึ่งต่อไปนี้ จะเรียกว่า “บริษัท” “เรา” “พวกเรา”)
          ผู้ให้บริการไซเบอร์เช็ค แอปพลิเคชัน
          (ต่อไปนี้จะเรียกแอปพลิเคชันไซเบอร์เช็คว่า “Cyber-Check” หรือ “แอปพลิเคชัน)
        </p>

        <li>บทนำ</li>

        <p>
          เรามีความยินดีที่ท่านสนใจ Cyber-Check และบริการต่างๆ ที่เราได้ให้บริการและเราเคารพต่อความเป็นส่วนตัวและข้อมูลส่วนบุคคลของท่าน
        </p>
        <p>
          เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พศ. 2562 (“พรบ. คุ้มครองข้อมูลส่วนบุคคล)
          ประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้จะแจ้งให้ท่านทราบว่า ข้อมูลส่วนตัวอะไรบ้างที่เราจะเก็บรวบรวม ใช้ แชร์
          และเปิดเผย เราจะเก็บข้อมูลเหล่านี้นานเพียงใด และใครที่เราจะนำข้อมูลไปเปิดเผย สิทธิต่างๆ ของท่าน
          และข้อมูลในการติดต่อเรา
        </p>
        <p>
          ในการที่ท่านใช้ Cyber-Check ท่านได้ตกลงยอมรับข้อตกลงการให้บริการของบริษัท
          (ปรากฏตามลิงค์ https://admin.cybercheck-ccib.com/th/privacy)
          และได้ยอมรับประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้แล้ว หากท่านไม่ยอมรับข้อตกลงการให้บริการของบริษัท
          และประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้ ท่านสามารถเลือกที่จะไม่ใช้บริการ Cyber-Check
          และลบหรือนำแอปพลิเคชันนี้ออกไปจากอุปกรณ์ของท่านได้ ทั้งนี้ ท่านสามารถศึกษาข้อมูลเพิ่มเติมเกี่ยวกับ
          “สิทธิของท่าน” ได้ ซึ่งปรากฏอยู่ในข้อ 7. ของประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้
        </p>
        <p>คำนิยาม</p>
        <p>
          คำนิยามต่างๆ ที่ใช้ในประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้
          จะมีความหมายเช่นเดียวกันกับคำนิยามที่ใช้ในข้อตกลงการให้บริการของบริษัท
          เว้นแต่จะได้มีการกำหนดคำนิยามเอาไว้เป็นอย่างอื่นในประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้
        </p>
        <p>
          โปรดศึกษาข้อตกลงการให้บริการของบริษัทได้ที่
          https://admin.cybercheck-ccib.com/th/privacy
          ซึ่งท่านจะได้พบข้อมูลเกี่ยวกับวิธีการจัดการข้อมูลของท่านก่อนที่ท่านจะตัดสินใจส่งข้อมูลมาให้เรา
        </p>

        <li>
          ข้อมูลส่วนบุคคลประเภทใดบ้างที่เราจะเก็บรวบรวมใช้แชร์และเปิดเผย
        </li>

        <p>
          “ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลใดๆ เกี่ยวกับบุคคล
          ซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม
        </p>
        <p>
          ข้อมูลส่วนบุคคลที่เราเก็บรวบรวมจากท่านได้แก่ข้อมูลดังต่อไปนี้
          (แต่ไม่จำกัดเพียงเท่าที่กล่าวด้านล่างนี้)
        </p>

        <p>
          “หมายเลขหรือรหัสของผู้โทร และ ข้อมูล SMS ” (Caller ID & SMS)
          หมายถึง ข้อมูลที่ถูกส่งออกตามที่ท่านได้เลือก (เช่น ข้อมูลที่ท่านส่งมาให้เราโดยสมัครใจ)
          และรวมถึงข้อมูลอื่นใดที่ท่านได้ส่งให้แก่เราเพื่อเป็นรหัสของผู้ใช้บริการ Cyber-Check ทั้งนี้
          ท่านได้ตกลงให้เราสามารถรีวิวและอ่าน “Caller ID & SMS Format” ซึ่งอยู่ในเครื่องโทรศัพท์ของท่านได้
          เพื่อที่เราจะได้ให้ข้อมูลกับท่านเกี่ยวกับผู้ให้บริการเครือข่ายโทรคมนาคม โดยข้อมูลดังกล่าว
          จะแสดงบน Cyber-Check Caller ID ในรูปแบบของเบอร์โทรศัพท์ของท่าน เช่น ชื่อของท่าน
          เบอร์โทรของท่าน และข้อมูลการติดต่ออื่นๆ ที่ท่านได้ให้ไว้
        </p>

        <li>เราเก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</li>

        <p>
          เราจะเก็บข้อมูลส่วนบุคคลของท่าน ตามที่ท่านได้ตกลงกับเราเพื่อที่จะแชร์ข้อมูลการติดต่อของท่าน
          และรวมถึงจากข้อมูลที่ท่านได้แสดงความยินยอมให้เข้าถึง
          และเราได้รวบรวมเฉพาะข้อมูลเบอร์โทรศัพท์ที่เป็นสายเรียกเข้า สายโทรออก
          ที่มีข้อมูลที่ตรงกับฐานข้อมูลของเราสำหรับการวิเคราะห์สถิติและความถี่เท่านั้น
          ไม่ได้เก็บรวบรวมเพื่อการดำเนินการทางธุรกิจ
        </p>

        <li>เราใช้ข้อมูลส่วนบุคคลของท่านอย่างไร</li>

        <p>
          ภายใต้ข้อตกลงการให้บริการ โครงการพัฒนาแอพพลิเคชั่น Cyber Check
          ในระยะแรกนี้  Cyber Check จะเปิดให้บริการให้แก่บุคคลทั่วไปสามารถเข้าใช้งานได้ทันที่
          เมื่อท่านทำการติดตั้งแอพพลิเคชันลงบนโทรศัพท์มือถือ โดยที่ยังไม่ต้องมีบัญชีผู้ใช้งาน ( Non User’ Account )
          แต่เพื่อการเข้าใช้งานได้อย่างเต็มประสิทธิภาพ เราจะขอสิทธ์การเข้าถึงข้อมูลของท่าน เพื่อการใช้งานดังต่อไปนี้
        </p>
        <p> 1.	ข้อมูลการโทร : เพื่อใช้ในการวิเคราะห์เบอร์โทรศัพท์กับฐานข้อมูลจากสำนักงานตำรวจแห่งชาติ
          ว่าเป็นเบอร์โทรศัพท์ที่มีความเสี่ยง, มีประวัติการแจ้งความ หรือได้รับการยืนยันตัวตนแล้วหรือไม่
          ซึ่งจะมีการเก็บข้อมูลและวิเคราะห์ความถี่เฉพาะเบอร์ที่เป็นข้อมูลตรงกับฐานข้อมูลเท่านั้น
        </p>
        <p>
          2.	ข้อมูล SMS : เพื่อตรวจสอบ URL ที่มีความเสียงสูง ที่อาจจะเป็นมิจฉาชีพส่งเข้ามา
        </p>

        <p /><li>ใครบ้างที่จะเข้าถึงข้อมูลของท่าน</li>

        </p>
        ใครบ้างที่จะเข้าถึงข้อมูลของท่าน
        </p>
        <p>
          เราจะทำให้มั่นใจว่า ข้อมูลส่วนบุคคลของท่านจะประมวลผลอย่างเหมาะสม
          และสอดคล้องกับวัตถุประสงค์ที่ระบุไว้ข้างต้น นอกจากนี้
          เราจะไม่แชร์ข้อมูลส่วนบุคคลของท่านให้กับบุคคลภายนอกที่ไม่ทำข้อตกลงไม่เปิดเผยข้อมูลอันเป็นความลับกับเรา
          ทั้งนี้ ข้อมูลของท่านจะถูกเปิดเผยต่อบุคคลดังต่อไปนี้
        </p>
        <p>
          ที่ปรึกษาด้านเทคนิค backend engineer เดเวลอปเปอร์นอกบริษัทด้านเทคนิค
          สมาชิกทีมผลิตภัณฑ์ และบุคคลภายนอกใดๆ ที่เป็นผู้ให้บริการ บริหารจัดการ
          ปรับปรุง บำรุงรักษา ซึ่งบริการต่างๆ ของเราและแอปพลิเคชัน
          หน่วยงานสาธารณะ องค์กรกับกับดูแลกฎหมายและกฎระเบียบ หน่วยงานราชการ
          หรือบุคคลภายนอกที่มีสิทธิตามกฎหมาย
          ที่ได้ปฏิบัติตามกฎหมายและกฎระเบียบที่เกี่ยวข้อง
          ในกรณีที่มีการควบรวมกิจการ ซื้อกิจการ ปรับโครงสร้างองค์กร ขายทรัพย์สิน
          หรือล้มละลายเราไม่อาจห้ามไม่ให้มีการซื้อขายกิจการ โอน หรือเปลี่ยนมือ
          ไม่ว่าด้วยลักษณะอื่นใด ไม่ว่าทั้งหมด หรือบางส่วน ดังนั้น
          ข้อมูลทางธุรกิจรวมถึงข้อมูลส่วนบุคคลของท่าน และ User Data หรือ Caller
          ID อาจถูกเข้าถึงหรือได้รับโดยบุคคลภายนอกได้

        </p><p /><li>เราจะเก็บข้อมูลส่วนบุคคลของท่านไว้นานเพียงใด</li>

        </p>
        <p>
          เราจะเก็บข้อมูลส่วนบุคคลของท่านไว้ตลอดระยะเวลาที่ท่านยังเป็นสมาชิกของเรา
          หรือใช้บริการของเราอยู่
          และเมื่อท่านตัดสินใจว่าจะยุติความสัมพันธ์กับเรา
          เราจะเก็บข้อมูลของท่านไว้อย่างน้อย 10 ปี หรือนานกว่านั้น
          เท่าที่จำเป็นสำหรับข้อมูลแต่ละประเภท
          และเพื่อวัตถุประสงค์ในการปฏิบัติตาม พรบ. คุ้มครองข้อมูลส่วนบุคคล
          ทังนี้ ระยะเวลาที่เก็บรักษาข้อมูลไว้
          จะขึ้นอยู่กับอายุความและกฎหมายหรือกฎระเบียบที่เกี่ยวข้อง
        </p>

        <li>สิทธิของท่าน</li>

        <p>
          ตาม พรบ. คุ้มครองข้อมูลส่วนบุคคล ท่านสามารถใช้สิทธิดังต่อไปนี้ได้
          ภายใต้ข้อกำหนดแห่งกฎหมายและภายใต้นโยบายของเรา
        </p>
        <p>
          สิทธิในการขอถอนความยินยอมที่ได้ให้ไว้กับเราเมื่อใดก็ได้
          โดยปฏิบัติตามวิธีและขั้นตอนที่เรากำหนดไว้
          เว้นแต่มีข้อจำกัดสิทธินั้นโดยกฎหมายหรือท่านยังคงได้ประโยชน์จากสัญญานั้นอยู่
          หรือโดยสภาพแล้วไม่อาจถอนความยินยอมได้
          สิทธิในการเข้าถึงข้อมูลและขอรับสำเนาข้อมูลส่วนบุคคลของท่านเอง
          สิทธิในการขอแก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง สิทธิในการขอลบ ทำลาย
          หรือทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนของท่านได้
          สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคลของท่าน
          สิทธิในการคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
          สิทธิในการขอโอนข้อมูลส่วนบุคคลของท่านไปยังผู้ควบคุมข้อมูลอื่นในรูปแบบที่สามารถอ่านได้ด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานโดยอัตโนมัติ
          เว้นแต่สภาพทางเทคนิคไม่สามารถทำได้
          สิทธิในการร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
          หรือต่อเจ้าหน้าที่ผู้มีอำนาจในกรณีที่เราไม่ปฏิบัติตาม
          พรบ.คุ้มครองข้อมูลส่วนบุคคล อย่างไรก็ตาม เราสามารถปฏิเสธคำขอของท่านได้
          ในกรณีที่มีเหตุผลเพียงพอ
          หรือคำขอนั้นส่งผลกระทบต่อสิทธิหรือเสรีภาพของบุคคลอื่นใด
          หรือมีกฎหมายที่เกี่ยวข้องหรือคำสั่งศาลให้สามารถปฏิเสธได้
        </p>
        <p>
          โปรดทราบว่า ข้อมูลส่วนบุคคลบางอย่างนั้น
          จะต้องให้ไว้กับเราเพื่อให้เราสามารถให้บริการกับท่านได้
          ซึ่งท่านสามารถเลือกที่จะให้ข้อมูลส่วนบุคคลเพิ่มเติมแก่เราผ่าน Cyber-Check
          Caller ID Data ได้ โปรดเข้าใจว่า
          หากท่านไม่สามารถปฏิบัติตามหรือไม่ตกลงที่จะให้ข้อมูลส่วนบุคคลของท่านแก่เราได้
          บริการ ต่างๆ ของ Cyber-Check
          อาจจะไม่สามารถให้บริการแก่คุณได้ไม่ว่าทั้งหมด หรือบางส่วน
        </p>
        <p>
          ถ้าท่านปฏิเสธการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
          ไม่ยอมรับการที่เราจะเก็บรวบรวมข้อมูลส่วนบุคคลของท่าน หรือขอให้เราลบ
          ทำลาย
          หรือระงับการใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ตามที่ได้แจ้งไว้ในประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้
          ท่านสามารถเลือกที่จะหยุดใช้ Cyber-Check หรือบริการต่างๆ จากเรา
          และติดต่อมายังแผนกบริการลูกค้า ผ่านทางช่องทางการให้บริการลูกค้าได้
          แต่ท่านจะไม่สามารถใช้บริการของ Cyber-Check ได้อีกต่อไป ไม่ว่าทั้งหมด
          หรือบางส่วน
        </p>

        <li>วิธีการติดต่อเรา</li>

        <p>
          ถ้าท่านมีข้อสงสัยเกี่ยวกับ ประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้
          หรือมีความประสงค์จะใช้สิทธิตามข้อ ท่านสามารถติดต่อเราได้ทางอีเมล์
          cybercheck@ccib.go.th
        </p>

        <li>ความปลอดภัย</li>

        <p>
          เราใส่ใจในการปกป้องข้อมูลส่วนบุคคลของท่าน
          เพื่อที่จะปกป้องข้อมูลส่วนบุคคลของท่านจากการเข้าถึงโดยไม่ได้รับอนุญาต
          เราจะใช้มาตรฐานความปลอดภัย อุปกรณ์
          และเครื่องมือทางอิเล็กทรอนิกส์ตามที่กฎหมายท้องถิ่นภายใต้เขตอำนาจศาลที่บริษัทของเราตั้งอยู่ได้กำหนดไว้
        </p>
        <p>
          เราจะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านนอกเหนือจากขอบเขตการให้บริการของเรา
          ความปลอดภัยของแอปพลิเคชันของเราเชื่อถือได้ เราใช้เทคโนโลยี AES double
          encryption ซึ่งเป็นการเข้ารหัสมาตรฐานขั้นสูงแบบสมมาตรสำหรับผู้ใช้งาน
          และ SSL encrypt ในระหว่างที่มีการส่งผ่านข้อมูล
          ข้อมูลที่ท่านให้ไว้แก่เราจะถูกรวบรวมไว้แบบการวิเคราะห์ข้อมูลทางสถิติที่ไม่ระบุตัวตน
          ซึ่งจะไม่สามารถระบุตัวตนของท่านได้
          เราเคร่งครัดกับข้อมูลของผู้ใช้และจะปกป้องข้อมูลส่วนบุคคลของท่านทุกวิถีทางที่ทำได้
          Cyber-Check
          จะไม่ขายหรือเปิดเผยข้อมูลของท่านอย่างผิดกฎหมายให้แก่บุคคลภายนอก
          การให้ความยินยอมนั้น
          เพียงเพื่อให้เราสามารถให้บริการที่ดีและแข็งแกร่งขึ้นกว่าเดิมเท่านั้น
          ท่านสามารถยกเลิกหรือปฏิเสธการให้ความยินยอมเมื่อใดก็ได้
          แต่การให้บริการก็จะถูกจำกัดเช่นกัน
        </p>
        <p>
          ท่านยอมรับว่า ข้อความหรือความเห็นใดๆ
          หรือเว็บไซต์ที่คุณแชร์บนแอปพลิเคชันของเราจะถูกเปิดเผยต่อสาธารณะ
          ข้อมูลใดที่ท่านเลือกที่จะให้กับเราในข้อความหรือความเห็น
          จะส่งผลต่อระดับข้อมูลที่ท่านต้องการให้ผู้ใช้อื่นทราบเกี่ยวกับตัวของท่าน
          เราขอแนะนำให้ท่านใช้ชื่อที่ไม่ระบุตัวตนและปกป้องข้อมูลอ่อนไหวของท่าน
          ในขณะเดียวกัน เราก็จะส่งเสริมให้ท่านและผู้ใช้งาน Cyber-Check
          ระมัดระวังการเปิดเผยข้อมูลในข้อความและความเห็นของตน
        </p>
        <p>
          เราอาจจะให้ผู้ให้บริการอื่นช่วยจัดการหรือจัดให้มีซึ่งบริการของ
          Cyber-Check แต่อย่างไรก็ตาม
          เราจะไม่อนุญาตให้ผู้ให้บริการอื่นนั้นเข้าถึงข้อมูลหรือติดต่อไปยังท่านหากข้อมูลไม่ได้ถูกเข้ารหัสไว้
          เว้นแต่จะจำเป็นต้องให้ผู้บริการอื่นดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลซึ่งเกี่ยวกับการให้บริการเฉพาะสำหรับท่าน
          ตามที่ท่านได้ตกลงแล้ว ยกตัวอย่างเช่น ถ้าท่านได้รับอีเมล์จาก Cyber-Check
          Service บริษัทที่ให้บริการจัดการด้านอีเมล์ซึ่งจ้างโดยเรา
          จะเข้าถึงข้อมูลอีเมล์ของท่าน
          โดยเราจะร้องขอให้ผู้ให้บริการนั้นให้คำรับรองกับเราเป็นลายลักษณ์อักษรว่าจะไม่เข้าถึงข้อมูลหรือติดต่อท่านโดยไม่ได้รับอนุญาต
          หรือเข้าถึงโดยไม่ชอบด้วยกฎหมาย หรือไม่ว่าด้วยวัตถุประสงค์อื่นใด
        </p>
        <p>
          เราจะเก็บข้อมูลแบบการวิเคราะห์ข้อมูลทางสถิติที่ไม่ระบุตัวตน
          ซึ่งจะไม่สามารถระบุตัวตนของท่านได้ รวมถึงเก็บข้อมูล log
          ระหว่างผู้ให้บริการเพียงเพื่อการวิเคราะห์ข้อมูล
          เพื่อที่เราจะได้ยกระดับการให้บริการของเรา
        </p>
        <p>
          เราจะนำ Caller ID และ ข้อมูล SMS  มาวิเคราะห์ข้อมูลทางสถิติที่ไม่ระบุตัวตน
          ซึ่งจะไม่สามารถระบุตัวตนของผู้เกี่ยวข้องได้ ซึ่งข้อมูลจะเป็นลักษณะความถี่ของการโทรเข้า หรือ โทรออก
          ของข้อมูลเบอร์โทรศัพท์ ที่มีความสัมพันธ์กับฐานข้อมูลของเราเท่านั้น และโปรดทราบว่า
          บริการของ Cyber-Check จะไม่เก็บบันทึก รวบรวม หรือเก็บข้อมูลการโทรของท่านเพื่อทำให้ท่านได้รับความเสี่ยง
          หรือเป็นผลร้ายกับท่าน
        </p>

        <p /><li>ลิงค์ไปยังเว็บไซต์อื่น</li>

        <p>
          แอปพลิเคชัน Cyber-Check มีลิงก์เมนูไปยังเว็บไซต์อื่น
          ซึ่งเป็นช่องทางรวบรวมแพลทฟอร์มที่สำนักงานตำรวจแห่งชาติสร้างขึ้น
          เพื่ออำนวยความสะดวกในการติดต่อประสานงาน หรือ ติดตามข่าวสาร
        </p>

        <li>เว็บไซต์ปลอมหรือหลอกลวง</li>

        <p>
          เราให้ความใส่ใจกับการโจรกรรมเอกลักษณ์บุคคล หรือเว็บไซต์ปลอมหรือหลอกลวง
          เราให้ความสำคัญกับการปกป้องข้อมูลส่วนบุคคลของท่านเป็นอันดับแรก
          เราจะไม่มีการถามท่านถึงข้อมูลบัตรเครดิตการ์ด บัญชีผู้ใช้ พาสเวิร์ด
          เลขบัตรประจำตัวประชาชน
          หรือข้อมูลระบุตัวตนอื่นใดของท่านผ่านทางอีเมล์หรือเบอร์โทรศัพท์ที่ไม่ปลอดภัยหรือที่ไม่สามารถระบุว่าเป็นเราได้
          ไม่ว่าจะในตอนนี้หรือในอนาคต
        </p>

        <li>การเปลี่ยนแปลงประกาศเกี่ยวกับความเป็นส่วนตัว</li>

        <p>
          ประกาศเกี่ยวกับความเป็นส่วนตัวฉบับนี้ อาจมีการแก้ไขเปลี่ยนแปลง
          หรือทำให้เป็นปัจจุบันขึ้นเป็นครั้งคราว Cyber-Check
          จะลงประกาศความเป็นส่วนตัวฉบับใหม่ของเราบนเว็บไซต์
          https://admin.cybercheck-ccib.com/th/privacy และบน
          แอปพลิเคชัน Cyber-Check การแก้ไขเปลี่ยนแปลงใดๆ
          ให้มีผลทันทีในวันที่ที่กำหนดไว้ในประกาศฉบับใหม่ซึ่งได้มีการลงประกาศแจ้งบนเว็บไซต์หรือแอปพลิเคชัน
          การที่ท่านเข้าใช้แอปพลิเคชันหรือเข้ามายังบริการต่างๆ
          ของเราอย่างต่อเนื่อง
          ถือว่าคุณได้ตกลงกับการแก้ไขเปลี่ยนแปลงประกาศเกี่ยวกับความเป็นส่วนตัวของเรา
          และยอมรับที่จะปฏิบัติตามให้สอดคล้องกับประกาศที่เปลี่ยนแปลงนั้น
          หากท่านไม่เห็นด้วยกับการแก้ไขเปลี่ยนแปลงใดๆ ท่านต้องหยุดใช้บริการต่างๆ
          หรือเข้าถึงแอปพลิเคชันที่เกี่ยวข้องใดๆ ของเรา
          เราขอแนะนำให้ท่านตรวจสอบประกาศเกี่ยวกับความเป็นส่วนตัวของเรารวมถึงนโยบายอื่นๆ
          เป็นระยะ เพื่อให้มั่นใจว่ามีการเปลี่ยนแปลงใดๆ หรือไม่
        </p>
        <p>Privacy Notice of Cyber-Check</p>
        <p>
          Cyber Police (hereinafter referred to as the "Company", "we", "us")
          Cybercheck Application Service Provider
          (hereinafter referred to as the Cybercheck Application) “Cyber-Check” or “Application)
        </p>

        <li>Introduction</li>

        <p>
          We are pleased that you are interested in Cyber-Check and our services.
          that we have provided services And we respect your privacy and personal Data.
        </p>
        <p>
          In accordance with the Thai Personal Data Protection Act B.E. 2562
          (“PDPA”), this privacy notice informs you of what type of personal
          data will be collected, used, shared and disclosed. How long we hold
          it, who we disclose it to, your rights and how to contact us.
        </p>
        <p>
          By using Cyber-Check, you had agreed to and accept the Company’s Terms of
          Services link https://admin.cybercheck-ccib.com/th/privacy and this privacy notice. If you
          do not accept our Terms of Services and this privacy notice, you have
          a choice not to use Cyber-Check and delete or remove the application from
          your device. Please find more detail about “Your Rights”  of this privacy notice.
        </p>
        <p>Definitions</p>
        <p>
          Definitions used in this Privacy Notice shall have the same meaning as the definition used in the Company's Terms of Service.
          Unless otherwise defined in this Privacy Notice.
        </p>
        <p>
          Please see the Company’s Terms of Service at
          https://admin.cybercheck-ccib.com/th/privacy , so you may find more information about
          how to manage the data you decide to submit to us.
        </p>

        <li>What personal data we collect, use, share and disclose</li>

        <p>
          “Personal data” means any information relating to a person which
          enables to identify a person, directly or indirectly.
        </p>
        <p>
          The personal data we collected from you shall include but not limited
          to the followings:
        </p>

        <p>
          “Caller ID & SMS” means information that is sent as selected by you (i.e. information that you voluntarily submit to us)
          and includes any other information that you. has been sent to us as a Cyber-Check user ID.
          You agree that we can review and read the “Caller ID & SMS Format”
          which is on your phone. So that we can provide you with information
          about telecommunications network providers. Such information will be displayed on Cyber-Check Caller ID
          in the form of your telephone number, such as your name, telephone number.
          and other contact information that you have given.
        </p>

        <li>How we collect your Personal Data</li>
        <p>We will collect your personal information.
          As you have agreed with us to share your contact information.
          and including from information that you have given your consent to access
          And we collect only incoming and outgoing phone numbers that match our database
          for statistical and frequency analysis only. It is not collected for business
          operations.
        </p>
        <li>How we use your personal data</li>
        <p>
          Under the service agreement In this first phase of the Cyber Check application development project,
          Cyber Check will be open to the general public and can be accessed
          immediately when you install the application on your mobile phone.
          Without having to have a user account (Non User' Account),
          but for full access to use. We will request permission to access your information.
          For use as follows.
        </p>
        <p>1. Call information: for use in analyzing telephone numbers
          with a database from the Royal Thai Police.
          that it is a phone number that is at risk, has a history of reporting
          Or has your identity been verified? Data will be collected and frequency
          analyzed only for numbers that match the database.</p>
        <p>
          2. SMS information: to check high-risk URLs that may be sent by fraudsters.
        </p>

        <li>Who will access to your personal data</li>
        </p>
        <p>
          We will ensure you that your personal data is processed in a proper
          manner and is compatible with the purposes as indicated above.
          Moreover, we do not share your personal data with any third party who
          have not entered into the non-disclosure agreement with us.
        </p>
        <p>Your personal data may be disclosed to the following parties:</p>
        <p>
          Technical consultant, backend engineer, external technical developers,
          product team member, and any third-party service provider to provide,
          manage, improve, and maintain our services and application. Public
          authorities, regulatory bodies, governmental bodies or any third party
          permitted by laws to comply with the applicable laws and regulations.
          In the case of merger, acquisition, reorganization, sale of assets or
          bankruptcy, we will not preclude its business from being sold,
          assigned, or transferred in any other manners, in whole or in part, so
          that the business information including your personal data and User
          Data or Caller ID might be accessed or received by a third party.
        </p>

        <li>How long we retain your personal data</li>

        <p>
          We will keep your personal information for the duration of your membership.
          or are using our services and when you decide to end your relationship
          with us We will keep your information for at least 10 years or longer.
          As necessary for each type of information and for the purpose of complying
          with the Personal Data Protection Act, the period for which the data is kept
          It will depend on the statute of limitations and related laws or regulations.
        </p>

        <li>Your Rights</li>

        <p>
          According to the PDPA, you may exercise the following rights within
          the legal requirements and our policies:
        </p>
        <p>
          Right to withdraw consent given to us at any time by means and methods
          specified by us, unless it is restricted by laws or you are still
          under beneficial agreement or the nature of consent does not allow
          such withdrawal. Right to access and obtain a copy of your personal
          data; Right to rectify your inaccurate personal data; Right to delete,
          destroy, or anonymize your personal data Right to restrict the use of
          your personal data Right to object the collection, use, or disclosure
          of your personal data Right to transfer of your personal data to other
          data controller in machine readable formats unless it is impossible to
          do so due to the technical circumstance. Right to file a complaint
          with the Personal Data Protection Committee or competent authorities
          in the event that we do not comply with the PDPA. However, we can
          reject your above request(s) if we have reasonable ground to reject,
          or such request will affect the right and freedom of other persons, or
          if there are any applicable laws or the courts’ orders not allow to do
          so.
        </p>
        <p>
          Please also be informed that some personal data must be provided for
          the use of the "Cyber-Check Service", but you may also choose to provide
          additional personal data in your personal "Cyber-Check Caller ID Data".
          You understand that when you fail or does not agree to provide some
          personal data as required, the "Cyber-Check Service" will not be able to
          perform the Service function, in whole or in part.
        </p>
        <p>
          If you object the collection, use or disclosure of your personal data,
          do not accept to allow us collecting your personal data or request to
          delete, destroy, or restrict the use of your personal data for the
          purpose as described in this privacy notice, you may choose to stop
          using Cyber-Check or any services from us and contact our customer’s
          service via the customer’s service channel, but you might not be able
          to use the "Cyber-Check Service" in whole or in part.
        </p>

        <li>How to contact us?</li>

        <p>
          If you have any queries regarding privacy notice or wish to exercise
          your rights in section 7, please contact us by e-mail to
          cybercheck@ccib.go.th
        </p>

        <li>Safety</li>

        <p>
          We care about the protection of your personal data. In order to
          protect your personal data from unauthorized access, we will apply the
          safety standards, hardware and electronic equipment required under the
          local laws applicable in the jurisdiction where we are situated.
        </p>
        <p>
          We will never divulge any of your personal information outside of our
          services. Our Application is liable for the security of user data, as
          a result we utilize double encryption technology: AES, a symmetric
          encryption algorithm on the user end, and SSL encrypted sessions
          during data transfer. All of your provided user information will be
          compiled into anonymous statistical analysis data that are not
          identifiable. We are very stringent with user data and will protect
          your privacy and security at all costs. Cyber-Check will never illegally
          sell or disclose your data to third parties outside of Cyber-Check
          services. Certain permissions are only requested to provide a better
          and more robust services. You can cancel and refuse to continue
          providing any permissions at any time you wish, but the full scope of
          services will be limited as a result.
        </p>
        <p>
          You agree that any messages or comments and related websites you share
          in the business blog or application of us may be disclosed to the
          public. Any data you choose to provide in your messages or comments
          will reflect the level of information which you hope the other users
          know about you. We suggest that you should use an anonymous name and
          protect sensitive data. Meanwhile, we also encourage you and any
          "Cyber-Check Users" to carefully think about their personal data to be
          disclosed in their messages or comments.
        </p>
        <p>
          We might retain a third party service provider to help it manage and
          provide the "Cyber-Check Service". Notwithstanding, unless the service
          provider is required to run the personal data involving the specific
          service you already agreed, we will not permit the service provider to
          access or contact your personal data when the data are not encrypted.
          For example, if you receive an email from the "Cyber-Check Service", the
          email management company commissioned by us will have the chance to
          access your email address. We will demand that the service provider
          should undertake and warrant in writing that it will not use the
          personal data accessed or contacted by them without permission or
          against laws, or for any other purposes.
        </p>
        <p>
          We will keep the "anonymous statistic analysis data",
          "Non-identifiable Data", and the logs between a third party for the
          purpose of analysis, so that we may continue to upgrade the Cyber-Check
          Service quality.
        </p>
        <p>
          We will combine your "User Data" and "Caller ID" with the other
          Cyber-Check Users’ data into the “anonymous statistic analysis
          information” which cannot afford to identify the concerned party, by
          virtue of the ‘de-identification” technology. Insofar as it is
          necessary to maintain your information safety, with respect to the
          user data provided by you to the “Cyber-Check Service”, the “anonymous
          statistic analysis data” exclude any “personal data”, and all of the
          "Personal Data" included therein will be separated and encrypted, and
          all related logs will be replaced by random numbers. In the Cyber-Check
          database, we are dedicated to completing de-identification with a
          series of long digit codes free from identifiable personal
          characteristics. All of the data about "Cyber-Check Visitors" used,
          searched or accessed by you will be identified and logged in said
          manner, and be analyzed, classified and identified based on the data
          about Cyber-Check users represented by a series of long digit codes.
          Please note that Cyber-Check Service will not log, collect or save your
          call record lest the risk over your use of data should be increased or
          your use of data should be affected adversely.
        </p>

        <li>Link to Other Sites</li>

        <p>
          The Cyber-Check application contains menu links to other websites. which is a channel for collecting platforms
          created by the Royal Thai Police To facilitate contact and coordination or
          follow the news.
        </p>

        <li>Fake sites</li>

        <p>
          We care about ID theft and "Fake Sites". We take the protection of
          your personal data as the first priority. We will never ask you for
          your credit card information, account and ID, password or national ID
          No. or equivalent identifiable codes via an unsafe or unknown email or
          phone number, now or in the future.
        </p>

        <li>Changes of the Privacy Notice</li>

        <p>
          This privacy notice may be changed or updated from time to time. If it
          is the case, Cyber-Check will post the updated privacy notice on our
          Website https://admin.cybercheck-ccib.com/th/privacy
          and via the Cyber-Check Application. The changes will take effective
          immediately on the effective date designated when the new Privacy
          Notice is promulgated. Your continuous access to the various services
          provided by us shall constitute your agreement to the changes and
          undertaking to comply with the changes. If you disagree to the
          changes, you shall stop accessing all services and related
          application. We suggest that you should check up our Privacy Notice
          including any other policies periodically to ensure whether there are
          any changes.
        </p>
      </div>
    </article>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
</style>
